import {Controller} from "stimulus";

// Moves all the child nodes from source to destination.
// The original childNodes NodeList is copied into an Array so that we
// can remove children from it as we iterate. Without this the length
// of the collection and the value at the current index of the iteration
// goes a little hay-wire.
const moveChildren = (source: HTMLElement, destination: HTMLElement) => {
  Array.from(source.childNodes).forEach((child) => {
    destination.appendChild(child.cloneNode(true));
    source.removeChild(child);
  });
};

// Use a pair of radio buttons to control whether or not a section of a
// form is present.
//
// See app/mounts/admin/views/admin/memberships/progress_notes/_form.html.erb
// for a usage example.
export default class extends Controller {
  static targets = ["section"];
  static values = {
    hidden: {default: true, type: Boolean},
    remove: {type: Boolean, default: true},
    scroll: {default: false, type: Boolean},
    validateChecked: {default: true, type: Boolean},
  };

  declare hiddenValue: boolean;
  declare removeValue: boolean;
  declare scrollValue: boolean;
  declare validateCheckedValue: boolean;
  declare template: HTMLElement;
  declare readonly sectionTargets: HTMLInputElement[];
  declare readonly hasSectionTarget: boolean;

  initialize() {
    this.template = document.createElement("template");
  }

  sectionTargetConnected() {
    if (this.hiddenValue) this.hideSection();
  }

  hide(event) {
    if (event.target.checked || !this.validateCheckedValue) {
      this.hideSection();
    }
  }

  show(event) {
    if (event.target.checked || !this.validateCheckedValue) {
      this.showSection();
    }
  }

  toggle() {
    if (this.hiddenValue) {
      this.showSection();
    } else {
      this.hideSection();
    }
  }

  showSection() {
    if (!this.hasSectionTarget) return;

    this.hiddenValue = false;

    if (this.removeValue) {
      this.sectionTargets.forEach(target => moveChildren(this.template, target));
    } else {
      this.sectionTargets.forEach(target => target.classList.remove("hidden"));
    }

    if (this.scrollValue) {
      this.sectionTargets[0].scrollIntoView({behavior: "smooth"});
      this.sectionTargets[0].focus({preventScroll: true});
    }
  }

  hideSection() {
    if (!this.hasSectionTarget) return;

    this.hiddenValue = true;

    if (this.removeValue) {
      this.sectionTargets.forEach(target => moveChildren(target, this.template));
    } else {
      this.sectionTargets.forEach(target => target.classList.add("hidden"));
    }
  }
}
